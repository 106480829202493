import React from 'react';
import PropTypes from 'prop-types';
import { bindMethods, camelToSnake, filterUnique } from "../../shared/functions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default class DynamicList extends React.Component {

    constructor(props) {
        super(props);
        bindMethods(this);
    }

    renderHeaders() {
        let headers = this.props.headers.map(header => {
            if (header.tooltip !== undefined) {
                return (
                    <th key={ header.key }>
                        { header.displayName }
                        &nbsp;
                        <OverlayTrigger
                            placement="bottom"
                            overlay={ <Tooltip id={ `${ header.key }-tooltip` }>{ header.tooltip }</Tooltip> }>
                            <span className="glyphicon glyphicon-question-sign"/>
                        </OverlayTrigger>
                    </th>
                )
            } else {
                return <th key={ header.key }>{ header.displayName }</th>
            }
        });

        if (this.props.policy.update) {
            headers.push(<th key="actions" className="actions"/>)
        }
        return headers;
    }

    renderRows() {
        return this.props.rows.map((row, rowNum) => {
            // doing a return here instead of a filter before to maintain the correct rowNum
            if (row._deleted) {
                return null;
            }

            const key = `row-${ rowNum }`;
            const renderedRow = this.props.headers.map(
                (header, colNum) => this.renderColumn(row, header, rowNum, colNum)
            );

            if (this.props.policy.update) {
                const onRemoveItem = (e) => {
                    e.preventDefault();
                    this.props.onRemoveItem(row, rowNum);
                };

                renderedRow.push(
                    <td key={ `${ rowNum }-remove` }>
                        <a className="delete-button"
                           href="#delete"
                           onClick={ onRemoveItem }>
                            <span className="glyphicon glyphicon-remove-circle"/>
                        </a>
                    </td>
                )
            }

            return (
                <tr key={ key }>
                    { renderedRow }
                </tr>
            )
        });
    }

    renderColumn(row, header, rowNum, colNum) {
        let errors;
        if (row.errors && Array.isArray(row.errors[header.key]) && row.errors[header.key].length > 0) {
            errors = (
                <div className="has-error">
                    <span className="help-block">
                        { row.errors[header.key].join('. ') }
                    </span>
                </div>
            )
        }

        const idInput = colNum === 0 ? this.renderIdField(row, rowNum) : null;

        let input;
        if (header.multiSelect) {
            input = this.renderMultiSelect(header, row, rowNum, colNum)
        } else if (header.boolean) {
            input = this.renderCheckbox(header, row, rowNum, colNum)
        } else if (header.dropDown) {
            input = this.renderDropDown(header, row, rowNum, colNum)
        } else {
            input = this.renderInput(header, row, rowNum, colNum);
        }

        return (
            <td key={ `${ rowNum }-${ header.key }` }>
                { idInput }
                { input }
                { errors }
            </td>
        )
    }

    renderInput(header, row, rowNum, colNum) {
        const onChangeItem = (e) => {
            const newValue = e.target.value;
            const updatedItem = { ...row, [header.key]: newValue };
            this.props.onChangeItem(updatedItem, rowNum)
        };

        let input;
        const columnName = this.getColumnName(row, rowNum, header.key);
        if (header.options && header.options.length > 0) {
            input = this.renderSelect(header, row, rowNum, colNum, columnName, onChangeItem)
        } else if (header.multiline) {
            input = <textarea
                className="form-control"
                value={ row[header.key] }
                name={ columnName }
                disabled={ this.disableInput(header, row, rowNum, colNum) }
                onChange={ onChangeItem }
            />
        } else if (header.fileInput) {
            const fileInput = header.fileInput;
            const { showCurrentFile, includeCache, accept } = fileInput;
            const cacheColumnName = this.getColumnName(row, rowNum, `${header.key}_cache`);

            input = <div className='row'>
                <div className={ showCurrentFile ? "col-sm-6" : "col-sm-12" }>
                    <input
                        type="file"
                        className="form-control"
                        name={ columnName }
                        accept={ accept }
                        disabled={ this.disableInput(header, row, rowNum, colNum) }
                        onChange={ onChangeItem }
                    />
                    { includeCache ? <input type="hidden" name={ cacheColumnName } value={ row?.asset_data_cache || "" }/> : ""}
                </div>
                { showCurrentFile &&
                    <div className='col-sm-6'>
                        <label className='control-label'>Current File: { row[header.key]?.identifier }</label>
                    </div>
                }
            </div>
        } else {
            input = <input
                type="text"
                className="form-control"
                value={ row[header.key] }
                name={ columnName }
                id={ columnName }
                disabled={ this.disableInput(header, row, rowNum, colNum) }
                onChange={ onChangeItem }
            />
        }

        if (this.props.renderInput) {
            return this.props.renderInput(input, header, row, rowNum, onChangeItem, columnName);
        }

        return input;
    }

    renderMultiSelect(header, row, rowNum, colNum) {
        const onChangeItem = (e) => {
            const newValue = e.target.value;
            let values = row[header.key] || []
            if (e.target.checked) {
                values = [...values, newValue].filter(filterUnique)
            } else {
                values = values.filter(value => value !== newValue)
            }

            const updatedItem = { ...row, [header.key]: values };
            this.props.onChangeItem(updatedItem, rowNum)
        };
        const options = header.options.map((option, index) => {
            return <li key={ `${ index }-checkbox` }>
                <label>
                    <input type="checkbox"
                           disabled={ this.disableInput(header, row, rowNum, colNum) }
                           name={ `${ this.getColumnName(row, rowNum, header.key) }[${ index }]` }
                           value={ option.value }
                           checked={ row[header.key].includes(option.value) }
                           onChange={ onChangeItem }
                    />
                    { option.displayName }
                </label>
            </li>
        });

        return <ul className="multi-select">{ options }</ul>

    }

    renderDropDown(header, row, rowNum, colNum) {
        const onSelectChange = (e) => {
            const newValue = e.target.value;
            const updatedItem = { ...row, [header.key]: newValue };
            this.props.onChangeItem(updatedItem, rowNum, header.key);
        };

        const columnName = this.getColumnName(row, rowNum, header.key);
        const options = header.options.map((option, index) => (
            <option key={ `${ index }-option` } value={ option.value }>
                { option.displayName }
            </option>
        ));
        return (
            <select
                disabled={ this.disableInput(header, row, rowNum, colNum) }
                name={ columnName }
                className="form-control"
                value={ row[header.key] }
                onChange={ onSelectChange }
            >
                { options }
            </select>
        );
    }

    renderSelect(header, row, rowNum, colNum, columnName, onChangeItem) {
        const options = header.options.map(
            option => <option key={ option.value } value={ option.value }>{ option.displayName }</option>
        );
        return (
            <select className="form-control"
                    value={ row[header.key] }
                    name={ columnName }
                    disabled={ this.disableInput(header, row, rowNum, colNum) }
                    onChange={ onChangeItem }>
                { options }
            </select>
        )
    }

    renderCheckbox(header, row, rowNum, colNum) {
        const onChangeItem = (e) => {
            const newValue = e.target.checked;
            const updatedItem = { ...row, [header.key]: newValue };
            this.props.onChangeItem(updatedItem, rowNum, header.key)
        }
        const columnName = this.getColumnName(row, rowNum, header.key);

        return <ul className="multi-select">
            <li>
                <label>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={ <Tooltip id={ "last-seen-tooltip" }>{ "a bundle identifier must be provided in order to make the extension removable" }</Tooltip> }>
                        <input type="checkbox"
                            disabled={ this.disableInput(header, row, rowNum, colNum) }
                            name={ columnName }
                            id={ columnName }
                            value={ "1" }
                            checked={ row[header.key] == true }
                            onChange={ onChangeItem }
                        />
                    </OverlayTrigger>
                    { header.optionDisplayName !== undefined ? header.optionDisplayName : header.displayName }
                </label>
            </li>
        </ul>
    }

    renderIdField(row, rowNum) {
        if (row.id) {
            return <input
                type="hidden"
                name={ `${ this.getColumnName(row, rowNum, 'id') }` }
                value={ row.id }
            />;
        }
    }

    getColumnName(row, rowNum, columnName) {
        return `${ this.props.fieldName }[${ rowNum }][${ camelToSnake(columnName) }]`;
    }

    renderAddButton() {
        if (this.props.policy.update) {
            const addItem = (e) => {
                e.preventDefault();
                this.props.onAddItem();
            };

            return <a className="add-button"
                      href="#add"
                      onClick={ addItem }>
                <span className="glyphicon glyphicon-plus-sign"/>
            </a>
        }
    }

    disableInput(header, row, rowNum, colNum) {
        if (!this.props.policy.update) {
            return true
        } else if (this.props.disableInput) {
            return this.props.disableInput(header, row, rowNum, colNum)
        } else {
            return false
        }
    }

    renderDeletedItems() {
        return this.props.rows
            .map((row, rowNum) => {
                    if (row['_deleted']) {
                        return (
                            <React.Fragment key={ `deleted-${ rowNum }` }>
                                { this.renderIdField(row, rowNum) }
                                <input
                                    type="hidden"
                                    name={ this.getColumnName(row, rowNum, '_destroy') }
                                    value="true"/>
                            </React.Fragment>
                        );
                    }
                }
            );
    }

    render() {
        return (
            <div className="dynamic-list">
                <table className="table table-condensed table-hover">
                    <thead>
                    <tr>
                        { this.renderHeaders() }
                    </tr>
                    </thead>
                    <tbody>
                    { this.renderRows() }
                    </tbody>
                </table>
                { this.renderAddButton() }
                { this.renderDeletedItems() }
            </div>
        )
    }
}

DynamicList.propTypes = {
    fieldName: PropTypes.string.isRequired,
    policy: PropTypes.object,
    headers: PropTypes.arrayOf(PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
        multiSelect: PropTypes.bool,
        multiline: PropTypes.bool,
        options: PropTypes.arrayOf(PropTypes.shape({
            displayName: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        }))
    })).isRequired,
    rows: PropTypes.arrayOf(PropTypes.object),
    renderInput: PropTypes.func,
    onChangeItem: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
    onAddItem: PropTypes.func.isRequired,
    disableInput: PropTypes.func  // optional function that returns true if the input should be disabled
};

DynamicList.defaultProps = {
    policy: {
        update: true
    }
};